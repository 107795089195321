import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router , ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  pass_type:string='password';
  isLoad:boolean=false;
  errormsg:string='';

  rememberEmail:any;

  constructor( public loginservice:LoginService,
               public router: Router,
               private activateroute: ActivatedRoute,
               public fb:FormBuilder,
               private toastr: ToastrService,
               private settingservice:SettingsService,
               private titleservice:Title) { }

  loginForm = this.fb.group({
    email    : ['',[Validators.required,Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/) ] ],
    password : ['',[Validators.required]]
  });

  loginFormData()
  {
     this.isLoad = true;
    this.loginservice.login(this.loginForm.value).subscribe(
      (res)=>{
               this.isLoad=false;
                localStorage.setItem('userdetails',JSON.stringify(res));
                localStorage.setItem('access',res.access);
                localStorage.setItem('refresh',res.refresh);
                //this.toastr.success('Login Success !');
                //console.log(res.roles);

                for(var i=0;i<res.roles.length;i++)
                {
                  let permission=[];
                     if(res.roles[i].roles == res.user_type)
                     {
                       let keys = ['dashboard','customers','invoices','payments','reports','billing_subscriptions','invoice_settings','roles_permissions'];
                       let values:any=[];
                       var result:any = {};
                       for(var j=0;j<res.roles[i].child.length;j++)
                       {
                         const singlepermission = res.roles[i].child[j];
                         //console.log(singlepermission.permission.replace(/ /g,"_"))
                         values.push(singlepermission.access)
                       }
                      keys.forEach((key, i) => result[key] = values[i]);
                      localStorage.setItem('permission',JSON.stringify(result));
                      localStorage.setItem('user_type',res.user_type);
                      //console.log(result);
                     }  
                }

                setTimeout(() => {
                  this.router.navigate(['/admin/dashboard'])
                }, 1000);
                
             },
      (err)=>{
        if (err.error.detail){
          this.toastr.error(err.error.detail[0]);
        }else{
          this.toastr.error("Oops something went wrong !", '', { timeOut: 1500 });
        }
              this.isLoad=false;
            }
    )
  }

  ngOnInit(): void {
   
    this.titleservice.setTitle('Login');


    this.activateroute.queryParams.subscribe((res:any)=>{

      if(res.invoice_id)
      {
        this.settingservice.setSchedulePayment(res.invoice_id).subscribe((res:any)=>{
          this.toastr.success(res.message,'', { timeOut: 4000 });
        },
        (err)=>{
          console.log(err);
        })
      }
       
    })

    if(this.loginservice.loggedIn()) //if user loggedIn then not open login 
    {
      this.router.navigate(['/admin/dashboard']);
    }

    this.remember = localStorage.getItem('email') != null ? true : false;
    if (localStorage.getItem('email') !== null && localStorage.getItem('email') !== 'null' &&  localStorage.getItem('password') !== null && localStorage.getItem('password') !== 'null' ){
    this.loginForm.patchValue({
                               "email":localStorage.getItem('email'),
                               "password":localStorage.getItem('password'),
                              });
                            }
  }


  goToDashboard()
  {
    this.router.navigate(['/admin/dashboard/']);
  }

  showPassword()
  {
    this.pass_type = this.pass_type == 'text' ? 'password' : 'text';
  }

  remember: Boolean=false;
  rememberMe()
  {
    this.remember = !this.remember;
    if(this.remember)
    {
      let email:any = this.loginForm.value.email;
      let password:any = this.loginForm.value.password;
      localStorage.setItem('email',email);
      localStorage.setItem('password',password);
    }
    else
    {
      localStorage.removeItem('email');
      localStorage.removeItem('password');
    }
  }



/** class end */
}
