import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntegraionStatusService {

  
  private statusSubject = new BehaviorSubject<string>(localStorage.getItem('integrationStatus') || 'false');
  integrationStatus$ = this.statusSubject.asObservable();
  private companyNameSubject = new BehaviorSubject<any>({})
  companyName$ =  this.companyNameSubject.asObservable()

  setIntegrationStatus(status: string) {
    localStorage.setItem('integrationStatus', status);
    this.statusSubject.next(status);
  }

  setCompanyName(name : string){
    this.companyNameSubject.next(name)
  }
  constructor() { }
}
