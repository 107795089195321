import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SocketioService } from 'src/app/services/socketio.service';

@Component({
  selector: 'app-invoice-payment-status',
  templateUrl: './invoice-payment-status.component.html',
  styleUrls: ['./invoice-payment-status.component.scss'],  
})
export class InvoicePaymentStatusComponent implements OnInit , OnDestroy{

  paymentAmount !: string
  PaymentData: any
  PaymentDate!: string
  tillNumber !: string
  paymentType !: string
  invoice : any
  mobileNumber !: string
  invoiceId  !: string
  paymentInfo: any

  constructor( private socketIoService: SocketioService ,private router : Router , private  cookieService : CookieService) {}

  ngOnInit(): void {
    const paymentString = this.cookieService.get("paymentInfo")
    this.paymentInfo = paymentString ? JSON.parse(atob(paymentString)) : null;
    this.PaymentData = this.paymentInfo.paymentData
    this.invoice = this.PaymentData.invoice
    this.tillNumber = this.paymentInfo.tillNumber
    this.mobileNumber = this.paymentInfo.mobileNumber
    this.invoice = this.paymentInfo.invoice
    this.PaymentDate = this.paymentInfo.paymentDate
  }
  ngOnDestroy(): void {
    this.cookieService.delete("paymentInfo")
    window.onbeforeunload = null;
  }
}
