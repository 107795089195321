import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NG_ASYNC_VALIDATORS, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceService } from 'src/app/services/invoice.service';
import { ToastrService } from 'ngx-toastr';
import { CountryISO } from 'ngx-intl-tel-input-gg';
import { SocketioService } from 'src/app/services/socketio.service';
import { CookieService } from 'ngx-cookie-service';

declare var $ : any

@Component({
  selector: 'app-invoice-payment',
  templateUrl: './invoice-payment.component.html',
  providers:[DatePipe],
  styleUrls: ['./invoice-payment.component.scss']
})
export class InvoicePaymentComponent implements OnInit {

  invoice : any
  invoiceId !: string
  paymentForm !: FormGroup
  paymentType: 'personal' |'business' = 'personal'
  CountryISO = CountryISO
  isValidPhoneNumber: boolean = true;
  showPhoneControl: boolean = true
  invalid_amount_msg: string = ''
  isLocal: boolean = true;
  submitted: boolean = false
  PaymentData: any
  paymentAmount: number = 0;
  mobileNumber !: string
  PaymentDate !: string | null
  isLoad: boolean = false;
  tillNumber !: string
  paymentInfo : any
  constructor(private _ActivatedRoute: ActivatedRoute,
              private fb: FormBuilder, 
              private router: Router, 
              private invoiceService: InvoiceService, 
              private toastr: ToastrService,
              private socktIoService : SocketioService, 
              private datePipe :DatePipe,
              private cookieService : CookieService
            ) {}
   
  ngOnInit(): void {
    this.formInit()
    this.socktIoService.mpesaPaymentData.subscribe((res: any)=>{
      this.PaymentData = res
      if(this.PaymentData && this.PaymentData?.payment_date && this.PaymentData.phone_number){
        const dateString = this.PaymentData?.payment_date.toString()
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6) - 1;
        const day = dateString.substring(6, 8);
        this.PaymentDate = this.datePipe.transform(new Date(year,month,day), 'dd-MM-yyyy')
      } else if (this.PaymentData && this.PaymentData?.payment_date && !this.PaymentData.phone_number){
        this.PaymentDate = this.datePipe.transform(new Date(this.PaymentData?.payment_date), 'dd-MM-yyyy')
      }
      this.mobileNumber = this.paymentForm.get('mobile_number')?.value?.e164Number
      this.paymentAmount = this.paymentForm.get('amount')?.value
      this.tillNumber = this.paymentForm.get('business_till_number')?.value
      this.paymentInfo = {
        "paymentAmount": this.paymentAmount, 
        "paymentData": this.PaymentData,
        "paymentDate": this.PaymentDate, 
        "mobileNumber": this.mobileNumber, 
        "tillNumber": this.tillNumber, 
        "invoice_number": this.invoice.invoice_number 
      }
      if(res){
      const ExpiryDate = new Date()
       this.cookieService.set("paymentInfo",btoa(JSON.stringify(this.paymentInfo)) , {expires : ExpiryDate.getHours() + 1 })
        if (res.result_code === 0 || res.result_code === '0'){
          $('#rcdoneModal3').modal('hide')
          if(this.PaymentData.invoice_id === this.invoiceId){
            this.getInvoice()
            window.close()
            window.open(`invoice/invoice-payment/${this.invoiceId}/payment-success`, '_self');
          }
       this.isLoad = false
      }else{
          $('#rcdoneModal3').modal('hide')
          if (this.PaymentData.invoice_id === this.invoiceId) {
            this.getInvoice()
            window.open(`invoice/invoice-payment/${this.invoiceId}/payment-failure`, '_self');
          }
        this.isLoad = false
      }
    }
    })
    this._ActivatedRoute.params.subscribe((res : any) => {
      this.invoiceId = res.id
      this.getInvoice()
    })
   
  }


  formInit(){
    this.paymentForm = this.fb.group({
      mobile_number : ['',Validators.required],
      // business_till_number:[''],
      amount: ['', [Validators.required,Validators.min(1)]],
      payment_type: ['personal']
    })
  }
  getInvoice(){
    this.invoiceService.getInvoiceForPayment(this.invoiceId).subscribe((res :any)=>{
      this.invoice=res
      this.paymentForm.get('amount')?.addValidators(Validators.max(this.invoice.due_amount))
      this.paymentForm.get('amount')?.updateValueAndValidity()
      this.socktIoService.initMpesaSocket(this.invoice.customer_id, this.invoiceId)
    })
  }
  // onPaymentTypeChange(value: 'personal' | 'business'){
  //   this.paymentType = value
  //   if (value === 'business'){
  //     this.paymentForm.get('mobile_number')?.setValue('')
  //     this.paymentForm.get('business_till_number')?.addValidators(Validators.required)
  //     this.paymentForm.get('amount')?.addValidators([Validators.required, Validators.min(10)])
  //     this.paymentForm.get('amount')?.updateValueAndValidity()
  //     this.paymentForm.get('mobile_number')?.clearValidators()
  //   }else{
  //     this.paymentForm.get('business_till_number')?.setValue('')
  //     this.paymentForm.get('mobile_number')?.addValidators(Validators.required)
  //     this.paymentForm.get('amount')?.clearValidators()
  //     this.paymentForm.get('amount')?.addValidators([Validators.required, Validators.min(1)])
  //     this.paymentForm.get('amount')?.updateValueAndValidity()
  //     this.paymentForm.get('business_till_number')?.clearValidators()
  //   }
  //   this.paymentForm.get('business_till_number')?.updateValueAndValidity()
  //   this.paymentForm.get('mobile_number')?.updateValueAndValidity()
  // }
  mPesaPayment(){
    const payload= {
      email : this.invoice.organization_email,
      customer_phone: this.paymentForm.get('mobile_number')?.value?.e164Number ,
      till_number: this.paymentForm.get('business_till_number')?.value,
      amount: this.paymentForm.get('amount')?.value,
      account_type: this.paymentForm.get('payment_type')?.value,
      customer_id: this.invoice.customer_id,
      invoice_id : this.invoice.id
    }
    if(this.paymentForm.valid){
    this.invoiceService.mPesaPayment(payload).subscribe(()=>{
      this.toastr.success('Payment request sent successfully');
      this.isLoad = true
      this.mobileNumber = this.paymentForm.get('mobile_number')?.value?.e164Number
      this.paymentAmount = this.paymentForm.get('amount')?.value
      this.tillNumber = this.paymentForm.get('business_till_number')?.value
      $('#rcdoneModal3').modal('show')
    },(error)=>{
      this.isLoad = false;
      if ((error.error.error).startsWith("\n")){
        const cleanedString = error.error.error.trim().replace(/\n\s*/g, '');
        const jsonObject = JSON.parse(cleanedString);
        this.toastr.error(jsonObject.errorMessage)
      }else if(error.error.error){
        this.toastr.error(error.error.error)
      }else{
        this.toastr.error("Oops something went wrong !", '', { timeOut: 1500 });
      }
    })
  }else{
    this.toastr.error("please fill valid details in form")
  }

  }

  validatePhoneNumber() {
    let parseDialCode = JSON.parse(JSON.stringify(this.paymentForm.get('mobile_number')?.value));
    if (
      this.paymentForm.value.mobile_number &&
      parseDialCode.dialCode === "+254"
    ) {
      const phonePattern = /^(?:\+254\s?)?(7|1)\d{8}$/;
      this.isValidPhoneNumber = phonePattern.test(
        parseDialCode.e164Number
      );
      if (this.isValidPhoneNumber) {
        this.paymentForm.get('mobile_number')?.setErrors(null);
        this.paymentForm.updateValueAndValidity();
      }
    } else {
      if (this.paymentForm.controls["mobile_number"].valid) {
        this.isValidPhoneNumber = true;
      } else {
        this.isValidPhoneNumber = false;
      }
    }
  }

  checkAmount(event: any) {
    if (Object(this.paymentForm.get('payment_mode')).value == 'Credit Memo') {
      if (this.isLocal ? this.invoice?.customer_obj?.jasiri_memos_remaining_credit > 0 : this.invoice?.customer_obj?.third_party_memos_remaining_credit > 0) {
        let min = Math.min(this.invoice.due_amount, this.isLocal ? this.invoice?.customer_obj?.jasiri_memos_remaining_credit : this.invoice?.customer_obj?.third_party_memos_remaining_credit);
        if (event.target.value > min) {
          if(this.paymentType === 'personal'){
          this.invalid_amount_msg = 'Amount should be less than or equal to ' + min;
            this.paymentForm.setErrors({ 'incorrect': true })
          }else{
            this.invalid_amount_msg = 'Amount should be less than or equal to '
          }
          this.submitted = true;
        } else {
          this.invalid_amount_msg = '';
          this.submitted = false;
        }
      }
    } else {
      let amount = event.target.value;
      if (amount > this.invoice.due_amount) {
        this.invalid_amount_msg = 'Amount should be less than or equal to ' + this.invoice.due_amount;
        this.paymentForm.setErrors({ 'incorrect': true })
        this.submitted = true;
      }
      else {
        this.invalid_amount_msg = '';
        this.submitted = false;
      }
    }
  }
}
