<div class="payment-faild-wrap" *ngIf="PaymentData.result_code !== 0">
    <div class="modalWrapper" id="rcdoneModal1">
       <div class="modalDialog">
          <div class="modal-content">
            <div class="modal-header-wrap">
              <div class="img-wrap">
                <img src="./assets/images/smalllogo.png" alt="logo"/>
              </div>
              <div class="header-content">
                <h4 class="header-title">KES {{paymentAmount}}</h4>
                <span class="header-subtitle">Payment for invoice {{invoice_number}}</span>
              </div>
            </div>
            <div class="modal-content-main-wrap">
              <div class="modal-contact-wrap" *ngIf="paymentType === 'personal'">
                <span class="contact-info">Mobile Number: </span>
                <span class="contact-info modal-value">{{mobileNumber}}</span>
              </div>
              <div class="modal-contact-wrap" *ngIf="paymentType === 'business'">
                <span class="contact-info">Business till number </span>
                <span class="contact-info modal-value">{{tillNumber}}</span>
              </div>
              <div class="modal-inner-message">
                <div class="icon-wrap">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                    <path d="M12 15H12.01M12 12V9M4.98207 19H19.0179C20.5615 19 21.5233 17.3256 20.7455 15.9923L13.7276 3.96153C12.9558 2.63852 11.0442 2.63852 10.2724 3.96153L3.25452 15.9923C2.47675 17.3256 3.43849 19 4.98207 19Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="modal-message-wrap">
                  <p class="modal-message-title">Payment Failed</p>
                  <p class="modal-message-subtitle">Your payment could not be processed </p>
                </div>
              </div>
              <div class="modal-detail-wrap">
                <div class="modal-detail-inner" *ngIf="PaymentData?.receipt_no">
                  <span class="modal-detail" >Transaction ID: </span>
                  <span class="modal-detail">{{PaymentData?.receipt_no}}</span> 
                </div>
                <div class="modal-detail-inner" *ngIf="PaymentData?.paymentReference">
                  <span class="modal-detail" >Payment Reference: </span>
                  <span class="modal-detail">{{PaymentData?.paymentReference}}</span> 
                </div>
                <div class="modal-detail-inner">
                  <span class="modal-detail" >Transaction Falied: </span>
                  <span class="modal-detail">{{PaymentData?.result_desc}}</span>  
                </div>
                <div class="modal-failed-btn-wrap">
                  <button type="button"  class="modalclose modalclosesuccess" id="closerecordpaymentmodal" (click) ="BackFailure()" data-bs-dismiss="modal">Back</button>
                </div>
              </div>
            </div>
          </div>
       </div>
    </div>
  </div>