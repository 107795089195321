// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //baseUrl : 'https://api.jasiricap.com',
  baseUrl: 'https://stage.api.jasiricap.com',
  // baseUrl: 'http://127.0.0.1:8000',
  mpesaSocketUrl: "wss://stage.api.jasiricap.com/ws/mpesa_data/",
  qb_client_id: 'ABQzXKoeKYjTbj4oLRKufchtoOdU5ExeS0cN9w8Gzt41fdqULy'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
