import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SocketioService } from 'src/app/services/socketio.service';

@Component({
  selector: 'app-invoice-payment-failure',
  templateUrl: './invoice-payment-failure.component.html',
  styleUrls: ['./invoice-payment-failure.component.scss']
})
export class InvoicePaymentFailureComponent implements OnInit ,OnDestroy{
  paymentAmount!: string
  PaymentData: any
  PaymentDate!: string
  tillNumber!: string
  paymentType!: string
  invoice_number: any
  mobileNumber!: string
  invoiceId !: string
  paymentInfo : any

  constructor( private socketIoService: SocketioService , private router : Router, private route : ActivatedRoute , private cookieService : CookieService ) {
  
  }

  ngOnInit(): void {
    if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
      if (confirm("Are you sure you want to reload the page?")) {
        const newPaymentString = this.cookieService.get("paymentInfo")
        const newPaymentInfo = newPaymentString ? JSON.parse(atob(newPaymentString)) : null;
        window.open(`invoice/invoice-payment/${newPaymentInfo.paymentData.invoice_id}`, '_self');
      } else {
      }
    } else {
    }
    const paymentString = this.cookieService.get("paymentInfo")
    this.paymentInfo = paymentString ? JSON.parse(atob(paymentString)) : null;
    this.PaymentData = this.paymentInfo.paymentData
    this.tillNumber = this.paymentInfo.tillNumber
    this.mobileNumber = this.paymentInfo.mobileNumber
    this.invoice_number = this.paymentInfo.invoice_number
    this.invoiceId = this.paymentInfo.paymentData.invoice_id
    this.PaymentDate = this.paymentInfo.paymentDate
    this.paymentAmount = this.paymentInfo.paymentAmount
  }
  ngOnDestroy(): void {
    this.cookieService.delete("paymentInfo")
  }

  CloseFailure(){
    this.cookieService.delete("paymentInfo")
    window.close()
  }

  BackFailure(){
    this.cookieService.delete("paymentInfo")
    window.close()
    window.open(`invoice/invoice-payment/${this.invoiceId}`, '_self');
  }
}
