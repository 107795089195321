<div class="modalWrapper" id="rcdoneModal2">
   <div class="modalDialog">
      <div class="modal-content">
        <div class="modal-content-main">
          <div class="modal-message-wrap">
             <div class="icon-wrap">
                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                <path d="M4 12.6111L8.92308 17.5L20 6.5"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
             </div>
            <div class="message-info">
              <h6 class="message-title">KES {{PaymentData.amount}}</h6>
              <span class="message-subtitle">Payment Successful</span>
            </div>
          </div>
            <div class="modal-info-wrap">
                <div class="modal-info-inner">
                  <span class="modal-info" > {{PaymentData?.result_code === 0 ? "Phone number:" :"Business till number:"}}</span> 
                  <span class="modal-info">{{PaymentData?.result_code === 0 ? PaymentData?.phone_number : tillNumber }}</span>
                </div>
                <div class="modal-info-inner">
                  <span class="modal-info">Payment Date:</span>
                  <span class="modal-info">{{PaymentDate}}</span>
                </div>
                <div class="modal-info-inner">
                  <span class="modal-info">{{PaymentData?.result_code === 0 ? "Receipt No:" : "Transaction ID:"}}</span>
                  <span class="modal-info">{{PaymentData?.result_code === 0 ? PaymentData?.receipt_no : PaymentData?.transaction_id}}</span>
                </div>
            </div>
            <!-- <div class="modal-btn-wrap">
              <button type="button" class="modalclose modalclosesuccess" id="closerecordpaymentmodal" data-bs-dismiss="modal">Ok</button>
            </div> -->
        </div>
      </div>
   </div>
</div>


