import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject, elementAt } from 'rxjs';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class SocketioService {

  chatSocket: WebSocket;
  //customerSocket!: WebSocket;
  //paymentSocket !: WebSocket
  //invoiceSocket !: WebSocket
  //dataSocket !: WebSocket
  mpesaSocket !: WebSocket

  public socketConnection = new Subject<any>();
  //public customerSocketConnection = new Subject<any>();
  //public paymentSocketConnection = new Subject<any>()
  //public invoiceSocketConnection = new Subject<any>()
  //public dataSocketConnection = new Subject<any>()
  public mpesaSocketConnection = new Subject<any>()
 
  notificationArray:any = [];
  //customerData =new  Subject<any[]>()
  //paymentData = new Subject<any[]>()
  //invoiceData = new Subject<any[]>()
  //deletedInvoices = new Subject<any[]>()
  //deletedPayments = new Subject<any[]>()
 mpesaPaymentData = new Subject<any[]>()
 mpesaPaymentData2 = new BehaviorSubject<any>({})

  notification_count:any=0
  is_socket_connect:boolean=false;
  chatSocketUrl:any="wss://stage.api.jasiricap.com/notification";
  // customerSocketUrl: string ="wss://stage.api.jasiricap.com/ws/customer/";
  // invoiceSocketUrl: string = "wss://stage.api.jasiricap.com/ws/invoice/";
  // paymentSocketUrl: string = "wss://stage.api.jasiricap.com/ws/payment/";
  mpesaSocketUrl: string = "wss://stage.api.jasiricap.com/ws/mpesa_data/";
  //dataSocketUrl: string = "wss://stage.api.jasiricap.com/ws/data/";
  
  constructor(private http: HttpClient, private toastr: ToastrService,) {
    this.chatSocket = new WebSocket(this.chatSocketUrl);
    // this.customerSocket = new WebSocket(this.customerSocketUrl)
    // this.dataSocket = new WebSocket(this.dataSocketUrl)
    // this.paymentSocket = new WebSocket(this.paymentSocketUrl);
    // this.invoiceSocket = new WebSocket(this.invoiceSocketUrl)
    // this.mpesaSocket = new WebSocket(this.mpesaSocketUrl)
  }
   

   initSocket() {
    this.chatSocket = new WebSocket(this.chatSocketUrl);
    let self = this;
    this.chatSocket.addEventListener('open', function (event) { 
      self.socketConnection.next('online');
      console.log('open');
      self.is_socket_connect = true;
    });
    this.chatSocket.addEventListener('message', function (event) { 
     //self.chatArr=JSON.parse(event.data)
     
     self.notificationArray.push(JSON.parse(event.data).payload);
     self.notification_count = JSON.parse(event.data).payload.notification_count;
    });
    this.chatSocket.addEventListener('close', function (event) {
      self.socketConnection.next('offline');
      self.is_socket_connect = false;
      console.log('offline');
      self.initSocket();
    });
  }

  // initDataSocket() {
  //   this.dataSocket = new WebSocket(this.dataSocketUrl);
  //   let self = this;

  //   this.dataSocket.addEventListener('open', function (event) {
  //     self.dataSocketConnection.next('online');
  //     console.log('Data socket open');
  //   });

  //   this.dataSocket.addEventListener('message', function (event) {
  //     const message = JSON.parse(event.data);
  //     if (message?.Data?.text_data) {
  //       self.toastr.error(message?.Data?.text_data)
  //     }

  //   });

  //   this.dataSocket.addEventListener('close', function (event) {
  //     self.dataSocketConnection.next('offline');
  //     console.log('data socket offline');
  //     self.reconnectSocket(self.initDataSocket.bind(self))
  //   });

  // }


  // initCustomerSocket() {
  //   this.customerSocket = new WebSocket(this.customerSocketUrl);
  //   let self = this;

  //   this.customerSocket.addEventListener('open', function (event) {
  //     self.customerSocketConnection.next('online');
  //     console.log('Customer socket open');
  //   });

  //   this.customerSocket.addEventListener('message', function (event) {
  //     const message = JSON.parse(event.data);

  //     if(typeof(message?.Data?.text_data) === 'string'){
  //       self.toastr.error(message?.Data?.text_data)
  //     }else{
  //     self.customerData?.next(message?.Data?.text_data[0])
  //     }
  //   });

  //   this.customerSocket.addEventListener('close', function (event) {
  //     self.customerSocketConnection.next('offline');
  //     console.log('Customer socket offline');
  //     self.reconnectSocket(self.initCustomerSocket.bind(self))
  //   });

  // }
//   initPaymntSocket() {
//     this.paymentSocket = new WebSocket(this.paymentSocketUrl);
//     let self = this;

//     this.paymentSocket.addEventListener('open', function (event) {
//       self.paymentSocketConnection.next('online');
//       console.log('Payment socket open');
//     });

//     this.paymentSocket.addEventListener('message', function (event) {
//       const message = JSON.parse(event.data);
//       if (message?.Data?.text_data) {
//         if (typeof (message?.Data?.text_data) === 'string') {
//           self.toastr.error(message?.Data?.text_data)
//         } else {
//           self.paymentData.next(message?.Data?.text_data[0])
//         }
//       } else {
//         self.deletedPayments.next(message.Data)
//       }
//     });

//     this.paymentSocket.addEventListener('close', function (event) {
//       self.paymentSocketConnection.next('offline');
//       console.log('Payment socket offline');
//       self.reconnectSocket(self.initPaymntSocket.bind(self))
//     });

// }
  // initInvoiceSocket() {
  //   this.invoiceSocket = new WebSocket(this.invoiceSocketUrl);
  //   let self = this;

  //   this.invoiceSocket.addEventListener('open', function (event) {
  //     self.invoiceSocketConnection.next('online');
  //     console.log('invoice socket open');
  //   });

  //   this.invoiceSocket.addEventListener('message', function (event) {
  //     const message = JSON.parse(event.data);
  //     if (message?.Data?.text_data){
  //       if (typeof (message?.Data?.text_data) === 'string'){
  //         self.toastr.error(message?.Data?.text_data)
  //       }else{
  //         self.invoiceData.next(message?.Data?.text_data[0])
  //       }
  //     }else{
  //       self.deletedInvoices.next(message.Data)
  //     }
  //   });

  //   this.invoiceSocket.addEventListener('close', function (event) {
  //     self.invoiceSocketConnection.next('offline');
  //     console.log('invoice socket offline');
  //     self.reconnectSocket(self.initPaymntSocket.bind(self))
  //   });
  // }

  initMpesaSocket(id1 :any, id2:any) {
    this.mpesaSocket = new WebSocket(this.mpesaSocketUrl + `${id1}/${id2}/`);
    let self = this;

    this.mpesaSocket.addEventListener('open', function (event) {
      self.mpesaSocketConnection.next('online');
      console.log('socket open');
    });

    this.mpesaSocket.addEventListener('message', function (event) {
      const message = JSON.parse(event.data);
      console.log("event ==>" , event)
      self.mpesaPaymentData.next(message?.Data?.text_data)
    });

    this.mpesaSocket.addEventListener('close', function (event) {
      self.mpesaSocketConnection.next('offline');
      console.log('socket offline');
      self.reconnectSocket(self.initMpesaSocket.bind(self,id1, id2))
    });
  }

  reconnectSocket(initSocketFn: () => void) {
    let delay = 1000;
    const reconnect = () => {
      setTimeout(() => {
        initSocketFn();
        delay = Math.min(delay * 2, 60000);
        reconnect();
      }, delay);
    };
    reconnect();
  }
}

