import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {  Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  previousUrl !: string

   //baseUrl = 'https://stage.api.jasiricap.com';
   baseUrl = environment.baseUrl
  constructor(private http:HttpClient , private router : Router) { 
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects
      });
  }


   login(user:any)
   {
     return this.http.post<any>(this.baseUrl+'/api/v1/account/login',user);
   }

   loggedIn()
   {
     return !!localStorage.getItem('access');
   }

   getToken()
   {
     return localStorage.getItem('access');
   }

   forgetPassword(data:any)
   {
     return this.http.post(this.baseUrl+'/api/v1/account/forgot-password',data);
   }

   resetPassword(data:any)
   {
     return this.http.post(this.baseUrl+'/api/v1/account/reset-password',data);
   }

   getPreviousUrl(){
    return this.previousUrl
   }
}
