<div class="payment-container payment-data-wrap">
    <h1>Payment For Invoice Number {{invoice?.invoice_number}}</h1>
    <div class="icondiv">
    </div>
  
    <div class="invoice-details">
      <div class="invoice-header">

        <div class="invoice-info">
            <img src="../assets/images/receipt_black.png" alt="Receipt Icon" class="invoice-icon">
           <div class=" ">
            <p>{{invoice?.invoice_number}}</p>
            <p>Due Date: {{invoice?.due_date | date:'d MMM yyyy'}}</p>
           </div>
         
        </div>

        <div class="invoice-summary">
            <div class="summary-item">
              <h4>Balance Due</h4>
              <h6 class="amount">KES {{invoice?.due_amount | number:'1.2-2'}}</h6>
            </div>
          </div>
      </div>
     
    </div>
  
    <form [formGroup]="paymentForm" (ngSubmit)="mPesaPayment()">                                                                                                                                                      
<div class="payment-type-selection">
    <label>
      <input
        type="radio"
        value="personal"
        formControlName="payment_type"
      />
      <span>Pay with my Personal M-Pesa Number</span>
    </label>
    <!-- <label>
      <input
        type="radio"
        value="business"
        formControlName="payment_type"
        (change)="onPaymentTypeChange('business')"
      />
      <span>Pay with my Business Till Number</span>
    </label> -->
  </div>
  
  
      <!-- Personal M-Pesa Payment -->
      <div *ngIf="paymentType === 'personal'" class="personal-payment">
        <label for="mobile_number">Phone Number:</label>
        <div class="phone-input" >
                       <ngx-intl-tel-input
                           [maxLength]="15"
                           [phoneValidation]="true"
                           [separateDialCode]="false"
                           [enablePlaceholder]="false"
                           name="phone"
                           formControlName="mobile_number"
                           (keyup)="validatePhoneNumber()"
                           [selectedCountryISO]="CountryISO.Kenya"
                           [onlyCountries]="[CountryISO.Kenya]"
                           class="phone-wrapper">
                     </ngx-intl-tel-input>
                     <small class="text-danger" *ngIf="!isValidPhoneNumber">
                     Invalid phone number
                     </small>
            </div>
    </div>
  
      <!-- Business M-Pesa Payment -->
      <!-- <div *ngIf="paymentType === 'business'" class="business-payment">
        <label for="business_till_number">Business Till Number:</label>
        <input type="text" id="business_till_number" formControlName="business_till_number" placeholder="Enter your business till number">
      </div> -->
      <label for="amount">Amount:</label>
      <input type="number" id="amount" formControlName="amount" min="1" (keyup)="checkAmount($event)" (wheel)="onWheel($event)" 
      placeholder="Enter amount">
      <small class="text-danger">
        {{invalid_amount_msg}}
     </small>
     <small class="text-danger" *ngIf="paymentForm.controls['amount'].invalid && paymentForm.controls['amount'].touched && paymentForm.hasError('min','amount') ">
      Amount should be greater than 10
     </small>
      <button type="submit" class="btn-primary" [disabled]="paymentForm.invalid">Submit Payment
        <img *ngIf="isLoad" src="./assets/images/load.gif"
                           style="width:30px;" />
      </button>
    </form>
  </div>
  
  <div class="payment-sucess-wrap">
    <div class="modal fade" id="rcdoneModal2">
       <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-content-main">
              <div class="modal-message-wrap">
                 <div class="icon-wrap">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                    <path d="M4 12.6111L8.92308 17.5L20 6.5"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                 </div>
                <div class="message-info">
                  <h6 class="message-title">KES{{paymentAmount}}</h6>
                  <span class="message-subtitle">Payment Successful</span>
                </div>
              </div>
                <div class="modal-info-wrap">
                    <div class="modal-info-inner">
                      <span class="modal-info" style="max-width: 100px;">{{PaymentData?.result_code === 0 ? "Phone number:" :"Business till number:"}}</span> 
                      <span class="modal-info">{{PaymentData?.result_code === 0 ? PaymentData?.phone_number : tillNumber }}</span>
                    </div>
                    <div class="modal-info-inner">
                      <span class="modal-info">Payment Date:</span>
                      <span class="modal-info">{{PaymentDate}}</span>
                    </div>
                    <div class="modal-info-inner">
                      <span class="modal-info">{{PaymentData?.result_code === 0 ? "Receipt No:" : "Transaction ID:"}}</span>
                      <span class="modal-info">{{PaymentData?.result_code === 0 ? PaymentData?.receipt_no : PaymentData?.transaction_id}}</span>
                    </div>
                </div>
                <div class="modal-btn-wrap">
                  <button type="button" class="modalclose modalclosesuccess" id="closerecordpaymentmodal" data-bs-dismiss="modal">Ok</button>
                </div>
            </div>
          </div>
       </div>
    </div>
 </div>
 <div class="payment-faild-wrap">
    <div class="modal fade" id="rcdoneModal1">
       <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header-wrap">
              <div class="img-wrap">
                <img src="./assets/images/smalllogo.png" alt="logo"/>
              </div>
              <div class="header-content">
                <h4 class="header-title">KES {{paymentAmount}}</h4>
                <span class="header-subtitle">Payment for invoice {{invoice?.invoice_number}}</span>
              </div>
            </div>
            <div class="modal-content-main-wrap">
              <div class="modal-contact-wrap" *ngIf="paymentType === 'personal'">
                <span class="contact-info">Mobile Number: </span>
                <span class="contact-info modal-value">{{mobileNumber}}</span>
              </div>
              <div class="modal-contact-wrap" *ngIf="paymentType === 'business'">
                <span class="contact-info">Business till number </span>
                <span class="contact-info modal-value">{{tillNumber}}</span>
              </div>
              <div class="modal-inner-message">
                <div class="icon-wrap">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                    <path d="M12 15H12.01M12 12V9M4.98207 19H19.0179C20.5615 19 21.5233 17.3256 20.7455 15.9923L13.7276 3.96153C12.9558 2.63852 11.0442 2.63852 10.2724 3.96153L3.25452 15.9923C2.47675 17.3256 3.43849 19 4.98207 19Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="modal-message-wrap">
                  <p class="modal-message-title">Payment Failed</p>
                  <p class="modal-message-subtitle">Your payment could not be processed </p>
                </div>
              </div>
              <div class="modal-detail-wrap">
                <div class="modal-detail-inner" *ngIf="PaymentData?.receipt_no">
                  <span class="modal-detail" >Transaction ID: </span>
                  <span class="modal-detail">{{PaymentData?.receipt_no}}</span> 
                </div>
                <div class="modal-detail-inner" *ngIf="PaymentData?.paymentReference">
                  <span class="modal-detail" >Payment Reference: </span>
                  <span class="modal-detail">{{PaymentData?.paymentReference}}</span> 
                </div>
                <div class="modal-detail-inner">
                  <span class="modal-detail" >Transaction Failed: </span>
                  <span class="modal-detail">{{PaymentData?.result_desc}}</span>  
                </div>
              </div>
            </div>
            <div class="modal-failed-btn-wrap">
              <button type="button"  class="modalclose modalclosesuccess" id="closerecordpaymentmodal" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
       </div>
    </div>
 </div>

 <div class="payment-faild-wrap">
  <div class="modal fade" id="rcdoneModal3">
     <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header-wrap">
            <div class="img-wrap">
              <img src="./assets/images/smalllogo.png" alt="logo"/>
            </div>
            <div class="header-content">
              <h4 class="header-title">KES {{paymentAmount}}</h4>
              <span class="header-subtitle">Payment for invoice {{invoice?.invoice_number}}</span>
            </div>
          </div>
          <div class="modal-content-main-wrap">
            <div class="modal-contact-wrap" *ngIf="paymentType === 'personal'">
              <span class="contact-info">Mobile Number: </span>
              <span class="contact-info modal-value">{{mobileNumber}}</span>
            </div>
            <div class="modal-contact-wrap" *ngIf="paymentType === 'business'">
              <span class="contact-info">Business till number </span>
              <span class="contact-info modal-value">{{tillNumber}}</span>
            </div>
            <div class="modal-inner-message">
              <div class="modal-message-wrap">
                <p class="modal-message-subtitle" style="color: black; margin-right: 10px; margin-left: 10px;"><strong>A payment request was sent to the  M-pesa app linked with the mobile number provided.</strong></p>
              </div>
            </div>
            <div class="initiate-payment-wrap">
              <span ><svg fill="#FFFF00" height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 52 52" xml:space="preserve" stroke="#f01919"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M26,50C12.767,50,2,39.233,2,26 S12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z"></path> <path d="M26,10c-0.552,0-1,0.447-1,1v22c0,0.553,0.448,1,1,1s1-0.447,1-1V11C27,10.447,26.552,10,26,10z"></path> <path d="M26,37c-0.552,0-1,0.447-1,1v2c0,0.553,0.448,1,1,1s1-0.447,1-1v-2C27,37.447,26.552,37,26,37z"></path> </g> </g></svg></span>
              <span> Please do not close or refresh this page until this payment is complete.</span>
              </div>
            </div>
  </div>
</div>